@import '../../AppConstants.scss';

.company {
  &__row {
    &--active {
      color: #ffffff;
      background-color: $default-background-color !important;
    }
  }
}
