@import './AdminLayoutConstants.scss';

.admin {
  padding-top: 56px;

  color: #333333;

  position: relative;
  z-index: 10;

  /*.navbar {
    background-color: $default-background-color;
  }

  .btn-outline-primary {
    color: $primary-color;
    border-color: $primary-color;
  }*/

  .page-title {
    margin: 20px 0;
  }
}
