@import '../AppConstants.scss';

.contractEditForm {
  .contractDraftSave {
    position: fixed;
    z-index: 5000;
    bottom: 0;
    left: 0;

    width: calc(100% + 30px);

    text-align: center;

    background-color: $default-background-color;
    color: #ffffff;

    padding: 15px 0;

    p {
      margin: 0;
    }
  }
}

input[type='date'],
input[type='time'] {
  font-size: 10px;
}

.required-text {
  color: #793b2f;
}




