.stats-card {
  border: 1px solid #192d80;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 25px;

  color: #192d80;

  &:hover {
    background-color: #b4bcc7;
    color: #ffffff;
  }

  &__count {
    text-align: center;
    font-size: 60px;
    padding: 0 15px;
  }

  &__desc {
    text-align: center;
  }

  &__btns {
    text-align: center;
    margin-top: 15px;
  }
}
