.broker {
  &__status {
    font-size: 20px;
    margin-left: 15px;
    vertical-align: middle;
  }

  &__switchBtn {
    margin-bottom: 15px;
  }
}
