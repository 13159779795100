.contract {
  &__item {
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 15px;
    padding: 25px;
    text-align: center;

    margin-bottom: 20px;

    position: relative;
  }

  &__title {
    text-align: left;
    margin-bottom: 25px;
    min-height: 60px;
    max-width: 60%;
  }

  &__state {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 16px;

    .btn {
      margin-right: 5px;
      padding: 0px 5px;
    }
  }

  &__btns {
    text-align: left;
  }

  &__innerBtn {
    position: absolute;
    top: -1px;
    left: -1px;

    height: calc(100% + 2px);
    width: 30px;
  }
}
