.terms-container {
  font-size: 18px;
  text-align: justify;
}

.terms-title {
  margin-top: 25px;
  margin-bottom: 25px;
}

.terms-link {
  color: #195dad;
}
