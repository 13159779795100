@import '../AppConstants.scss';

/*
 * Global styles
 */

/*
 * Header
 */
.navbar-collapse.show .navbar-nav {
  background-color: $default-background-color;

  a {
    span {
      border-right: none;
    }
  }
}

.navbar {
  .navbar-nav {
    margin: auto;
    position: relative;
    top: 85px;

    a {
      font-size: 16px;

      span {
        border-right: 1px solid #ffffff;
        padding-right: 15px;
      }

      &:last-child {
        span {
          border-right: none;
        }
      }
    }
  }

  &__login {
    width: 400px;
    position: absolute;
    top: 30px;
    right: 30px;

    @media screen and (max-width: 1200px) {
      width: 300px;
    }

    @media screen and (max-width: 800px) {
      width: 200px;
      top: 10px;
    }

    @media screen and (max-width: 992px) {
      justify-content: center;
    }

    .loginForm {
      @media screen and (max-width: 992px) {
        margin-top: 0;
      }

      &__label {
        display: none;
      }

      &__group {
        margin-bottom: 5px;
      }

      &__submit {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.ecpy-header {
  background-color: $default-background-color;
  background-image: url('navbar.jpg');
  background-size: cover;

  color: #ffffff;
  min-height: 130px;
  padding-bottom: 15px;
  position: relative;

  z-index: 10;

  &__title {
    font-size: 24px;
    color: #ffffff;
    text-align: left;
    max-width: 440px;
    text-transform: uppercase;
    padding-top: 13px;
  }
}

#root > .container {
  position: relative;
  z-index: 10;
}
