@import '../../AppConstants.scss';

.company-name-search {
  &__row {
    cursor: pointer;

    &--active {
      color: #ffffff;
      background-color: $default-background-color !important;
    }

    :hover {
      color: #ffffff;
      background-color: $default-background-color;
    }
  }
}
