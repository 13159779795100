@import '../AppConstants.scss';

.libraryDocs {
  &-list {
    &__item {
      position: relative;

      margin-bottom: 25px;
      border-radius: 25px;
      padding: 35px;

      background-color: #ffffff;

      color: #192d80;

      display: flex;
      flex-direction: row;
      align-items: center;

      &:nth-child(2n) {
        background-color: #a2acb9;

        .libraryDocs__icon-trash {
          border-left: 1px solid #ffffff;
        }
      }
    }
  }

  &__title {
    color: #192d80;
    font-size: 20px;
    margin: 0;
    max-height: 24px;
    overflow: hidden;
    flex: 1;
  }

  &__link {
    display: block;
    color: #192d80;
  }

  &__icon {
    font-size: 40px;
    margin-right: 20px;

    &-trash {
      cursor: pointer;
      padding-left: 20px;
      margin-right: 0;

      border-left: 1px solid #a2acb9;
    }
  }
}
